<script setup lang="ts">
import type { IconData } from '@/types/icon.model'
import CustomIcon from './CustomIcon.vue'
const iconData: IconData = {
  path: 'M8.18604 12.0654H14.6938C15.2681 12.0654 15.5688 12.7764 15.1587 13.1865L11.9048 16.4404C11.6313 16.7139 11.2212 16.7139 10.9751 16.4404L7.72119 13.1865C7.28369 12.7764 7.58447 12.0654 8.18604 12.0654ZM15.1587 9.19434C15.5688 9.63184 15.2681 10.3154 14.6938 10.3154H8.18604C7.58447 10.3154 7.28369 9.63184 7.72119 9.19434L10.9751 5.94043C11.2212 5.69434 11.6313 5.69434 11.9048 5.94043L15.1587 9.19434Z',
  fill: ''
}
</script>
<template>
  <CustomIcon :size="22" color="#FFFFFF" viewBox="0 0 22 22" :d="[iconData]">
    <circle cx="11" cy="11" r="11" fill="#146EB3" />
  </CustomIcon>
</template>
