import { defineStore } from 'pinia'
import { ApiService } from '@/services/api-service'
import type { SearchAssessment } from '@/types/search-results.model'
import { ROUTER_NAMES } from '@/util/router-names.constants'

const apiService = new ApiService()

const initSearchAssessment = {
  assessment_id: 0,
  assessment_guid: '',
  assessment_type: '',
  nib_assessment_id: 0,
  external_assessment_id: 0,
  duplicate_from_assessment_id: 0,
  lib_assessment_id: 0,
  is_installed: true,
  created_at: '',
  updated_at: '',
  deleted_at: '',
  published_at: '',
  queried_at: '',
  title: '',
  description: '',
  tags: [],
  user: {
    id: 0,
    name: '',
    account_id: '',
    first_name: '',
    last_name: ''
  },
  field_count: 0,
  field_types: [],
  field_types_count: 0,
  tags_series: [],
  standards: [],
  standards_count: 0,
  grades: [],
  subject: {
    id: '',
    name: ''
  },
  is_draft: false,
  is_renaissance: false
}

export const useAssessmentStore = defineStore('assessmentStore', {
  state: (): SearchAssessment => ({
    assessment: initSearchAssessment,
    showFullScreenButton: false,
    showDescription: false
  }),
  actions: {
    async fetchAssessmentDetail(assessmentGuid: string) {
      const searchAssessment = {
        assessmentGuid,
        resultLimit: 1
      }
      const result = await apiService.fetchAssessmentById(searchAssessment)
      this.assessment = { ...result.data.assessments[0] }
    },
    setShowFullScreenButton(showFullScreen: boolean) {
      this.showFullScreenButton = showFullScreen
    },
    setShowDescription(showDescription: boolean) {
      this.showDescription = showDescription
    },
    isAssessmentFullscreen() {
      const route = this.router.currentRoute.value.name
      return route === ROUTER_NAMES.ASSESSMENT_DETAIL_PAGE
    }
  }
})
