<script setup lang="ts">
import { useAuthStore } from '@/stores/auth'
import FaviconIcon from './icons/FaviconIcon.vue'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { ROLES } from '@/util/user-types.constants'

const roleMapping: { [key: string]: string } = {
  staff: ROLES.STAFF,
  illuminateed_employee: ROLES.ADMIN,
  default: ROLES.USER
}

const tooltipRef = ref<any>(null)
const authStore = useAuthStore()
const fullName = computed(() => authStore.getUserName)
const firstName = computed(() => authStore.getFirstName)
const lastName = computed(() => authStore.getLastName)
const site = computed(() => authStore.getClientName)
const type = computed(() => authStore.getUserType)

const getInitials = computed(() => {
  const first = firstName.value && firstName.value !== '' ? firstName.value.charAt(0) : 'A'
  const last = lastName.value && lastName.value !== '' ? lastName.value.charAt(0) : 'U'
  return `${first}${last}`
})

const getRole = computed(() => {
  const userType = type.value || 'default'
  return roleMapping[userType] || roleMapping.default
})
const show = ref(false)
function closeTooltip() {
  show.value = false
}

function handleClickOutside(event: MouseEvent) {
  if (
    tooltipRef.value.activatorEl &&
    !tooltipRef.value.activatorEl.contains(event.target as Node)
  ) {
    closeTooltip()
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>
<template>
  <v-app-bar :elevation="2">
    <template v-slot:prepend>
      <FaviconIcon data-test="logo-dna" class="header__icon" />
    </template>
    <template v-slot:append>
      <v-tooltip
        ref="tooltipRef"
        v-model="show"
        content-class="header__tooltip"
        :contained="true"
        :open-on-hover="false"
        offset="2"
        location="bottom"
      >
        <template v-slot:activator="{ props }">
          <v-btn
            data-test="logo-user-button"
            class="header__tooltip__button mr-4"
            :active="show"
            :ripple="false"
            icon
            v-bind="props"
            @click="show = !show"
          >
            {{ getInitials }}
          </v-btn>
        </template>
        <article data-test="user-tooltip-content" class="d-flex flex-column">
          <span>
            <span data-test="user-tooltip-full-name" class="tooltip__content__user">{{
              `${fullName} - `
            }}</span>
            {{ getRole }}</span
          >
          <span data-test="user-tooltip-site"> {{ site }} </span>
        </article>
      </v-tooltip>
    </template>
  </v-app-bar>
</template>

<style scoped>
:deep(.v-tooltip .header__tooltip) {
  background-color: #ffffff;
  color: #54585e;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding: 12px;
  border-radius: 8px;
}
:deep(.v-btn--active > .v-btn__overlay) {
  opacity: 0;
}
:deep(.v-btn--active:hover > .v-btn__overlay) {
  opacity: 0;
}
.header__icon {
  margin-left: 16px;
  height: 40px !important;
}
.header__tooltip__button:hover {
  border: 2px solid #cf3a4e;
}
.header__tooltip__button {
  font-weight: 500;
  font-size: 18px;
  line-height: 21.09px;
  font-family: 'Cerebri Sans Medium';
  background-color: #c6eddf;
}
.tooltip__content__user {
  color: #000000;
}
</style>
