import type { SearchState } from '@/types'
import { ResultType } from '@/types/filters.model'

function encodeBase64Url(data: Uint8Array): string {
  return btoa(String.fromCharCode(...Array.from(data)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

function decodeBase64Url(data: string): Uint8Array {
  const base64Encoded = data.replace(/-/g, '+').replace(/_/g, '/')
  const padding = data.length % 4 === 0 ? '' : '='.repeat(4 - (data.length % 4))
  const base64WithPadding = base64Encoded + padding
  return Uint8Array.from(atob(base64WithPadding), (c) => c.charCodeAt(0))
}

export function serializeStateToQuery(state: Partial<SearchState>): Record<string, string> {
  const dataToEncode = {
    resultType: state.resultType,
    searchCriteria: state.searchCriteria
  }

  const jsonString = JSON.stringify(dataToEncode)
  const encoder = new TextEncoder()
  const encodedData = encoder.encode(jsonString)
  const base64Encoded = encodeBase64Url(encodedData)

  return { search: base64Encoded }
}

export function deserializeQueryToState(
  query: Record<string, string | string[] | undefined>
): Partial<SearchState> {
  const searchParam = getQueryParam(query, 'search')

  if (!searchParam) {
    return {}
  }

  try {
    const encodedData = decodeBase64Url(searchParam)
    const decoder = new TextDecoder()
    const jsonString = decoder.decode(encodedData)
    const parsedData = JSON.parse(jsonString)

    return {
      resultType: parseResultType(parsedData.resultType),
      searchCriteria: parsedData.searchCriteria
    }
  } catch (error) {
    console.error('Failed to decode and parse search query:', error)
    return {}
  }
}

function parseResultType(value: string): ResultType {
  return Object.values(ResultType).includes(value as ResultType)
    ? (value as ResultType)
    : ResultType.ALL_RESULTS
}

function getQueryParam(query: Record<string, string | string[] | undefined>, key: string): string {
  const value = query[key]
  if (Array.isArray(value)) return value[0] || ''
  return value || ''
}
