<script lang="ts" setup>
import BaseModal from '@/components/modals/BaseModal.vue'
import LeavingModal from './LeavingModal.vue'
import type { ModalData, RadioGroupModel } from '@/types'
import { ref, watch } from 'vue'
import { useModalStore } from '@/stores/modal'
const emit = defineEmits(['radioGroupValueSelected'])
const props = defineProps({
  radioGroup: {
    type: Array<RadioGroupModel>,
    required: true
  },
  uniqueId: {
    type: String,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  radioGroupLabel: {
    type: String,
    required: false,
    default: ''
  },
  labelButton: {
    type: String,
    required: false,
    default: 'Continue'
  }
})
const valueSelected = ref<string>(props.radioGroup[0].value)
const modalStore = useModalStore()
let callBackFunction = ref<Function>(
  props.radioGroup.find((prop) => prop.value === valueSelected.value)?.callback as Function
)
watch(valueSelected, (newValue) => {
  callBackFunction.value = props.radioGroup.find((prop) => prop.value === newValue)
    ?.callback as Function
  emit('radioGroupValueSelected', newValue)
})
const modalData: ModalData = {
  modalHeader: {
    title: props.title,
    includeCloseBtnInHeader: true
  },
  modalActions: {
    buttons: {
      okButton: {
        label: props.labelButton,
        bgColor: '#008272',
        color: 'white',
        textTransform: 'capitalize',
        size: 'large',
        callback: () => {
          modalStore.closeModal(props.uniqueId)
          modalStore.openModal(`Leaving-Administer-${props.uniqueId.split('-')[1]}`, {
            modalOpened: true
          })
        }
      },
      cancelButton: {
        label: 'Cancel',
        bgColor: '#ffffff',
        color: '#050F2D'
      }
    }
  },
  modalSize: {
    maxWidth: 600,
    minWidth: 320
  }
}
</script>
<template>
  <BaseModal class="base-modal" :modalData="modalData" :uniqueId="uniqueId">
    <v-radio-group v-model="valueSelected" :label="props.radioGroupLabel">
      <template v-for="prop in radioGroup" :key="prop.value">
        <v-row class="standard-section__row ma-0">
          <v-col cols="11" class="pa-0">
            <v-radio
              baseColor="#7E8494"
              color="#008272"
              :label="prop.label"
              :value="prop.value"
              :data-test="`radio-${prop.value.toLowerCase()}`"
              :aria-label="`${prop.label}, ${prop.description}`"
            ></v-radio>
            <p class="pl-10 standard-section__description" :aria-hidden="true">
              {{ prop.description }}
            </p>
          </v-col>
        </v-row>
      </template>
    </v-radio-group>
    <slot></slot>
  </BaseModal>
  <LeavingModal
    :uniqueId="`Leaving-Administer-${props.uniqueId.split('-')[1]}`"
    :callbackFunction="callBackFunction"
  />
</template>
<style scoped>
.standard-section {
  padding-left: 10px;
}
.standard-section__row:not(:last-child) {
  padding-bottom: 16px;
}
.standard-section__description {
  font-size: 14px;
  line-height: 17.78px;
  color: #495b8f;
  text-wrap: wrap;
}
:deep(.v-label) {
  font-size: 14px;
  line-height: 17.78px;
  color: #050f2d;
  opacity: unset;
  margin-left: 2px;
}
:deep(.v-selection-control__input) > .v-icon {
  opacity: unset;
}
:deep(.v-selection-control--density-default) {
  --v-selection-control-size: 38px;
}
:deep(.v-input__details) {
  display: none;
}
</style>
