<script setup lang="ts">
import type { IconData } from '@/types/icon.model'
import CustomIcon from './CustomIcon.vue'
const iconData: IconData[] = [
  {
    path: 'M5 23V9.5H26V23C26 23.8438 25.2969 24.5 24.5 24.5H6.5C5.65625 24.5 5 23.8438 5 23ZM12.5 13.0625V13.4375C12.5 13.7656 12.7344 14 13.0625 14H17.9375C18.2188 14 18.5 13.7656 18.5 13.4375V13.0625C18.5 12.7812 18.2188 12.5 17.9375 12.5H13.0625C12.7344 12.5 12.5 12.7812 12.5 13.0625ZM26 3.5C26.7969 3.5 27.5 4.20312 27.5 5V7.25C27.5 7.67188 27.125 8 26.75 8H4.25C3.82812 8 3.5 7.67188 3.5 7.25V5C3.5 4.20312 4.15625 3.5 5 3.5H26Z',
    fill: 'white'
  },
  {
    path: 'M5 9.5V7.5H3V9.5H5ZM26 9.5H28V7.5H26V9.5ZM7 23V9.5H3V23H7ZM5 11.5H26V7.5H5V11.5ZM24 9.5V23H28V9.5H24ZM24 23C24 22.8492 24.0665 22.7187 24.1499 22.6371C24.2272 22.5614 24.3467 22.5 24.5 22.5V26.5C26.3607 26.5 28 24.9887 28 23H24ZM24.5 22.5H6.5V26.5H24.5V22.5ZM6.5 22.5C6.60959 22.5 6.743 22.5474 6.84781 22.6522C6.95262 22.757 7 22.8904 7 23H3C3 24.9483 4.55168 26.5 6.5 26.5V22.5ZM10.5 13.0625V13.4375H14.5V13.0625H10.5ZM10.5 13.4375C10.5 14.8702 11.6298 16 13.0625 16V12C13.3876 12 13.7688 12.1239 14.0724 12.4276C14.3761 12.7312 14.5 13.1124 14.5 13.4375H10.5ZM13.0625 16H17.9375V12H13.0625V16ZM17.9375 16C19.2141 16 20.5 14.9764 20.5 13.4375H16.5C16.5 12.5549 17.2234 12 17.9375 12V16ZM20.5 13.4375V13.0625H16.5V13.4375H20.5ZM20.5 13.0625C20.5 12.2714 20.1246 11.6477 19.7384 11.2616C19.3523 10.8754 18.7286 10.5 17.9375 10.5V14.5C17.4277 14.5 17.0852 14.2652 16.91 14.09C16.7348 13.9148 16.5 13.5723 16.5 13.0625H20.5ZM17.9375 10.5H13.0625V14.5H17.9375V10.5ZM13.0625 10.5C11.5236 10.5 10.5 11.7859 10.5 13.0625H14.5C14.5 13.7766 13.9451 14.5 13.0625 14.5V10.5ZM26 5.5C25.806 5.5 25.688 5.4188 25.6346 5.36539C25.5812 5.31197 25.5 5.19403 25.5 5H29.5C29.5 3.09856 27.9014 1.5 26 1.5V5.5ZM25.5 5V7.25H29.5V5H25.5ZM25.5 7.25C25.5 6.84839 25.6803 6.53239 25.8818 6.33923C26.0714 6.15753 26.3663 6 26.75 6V10C28.1485 10 29.5 8.85581 29.5 7.25H25.5ZM26.75 6H4.25V10H26.75V6ZM4.25 6C4.93269 6 5.5 6.56731 5.5 7.25H1.5C1.5 8.77644 2.72356 10 4.25 10V6ZM5.5 7.25V5H1.5V7.25H5.5ZM5.5 5C5.5 5.15326 5.43862 5.27276 5.36293 5.3501C5.28127 5.43353 5.1508 5.5 5 5.5V1.5C3.01135 1.5 1.5 3.13932 1.5 5H5.5ZM5 5.5H26V1.5H5V5.5Z',
    fill: '#008272',
    mask: 'url(#path-1-outside-1_10332_34931)'
  }
]
</script>
<template>
  <CustomIcon :size="17" color="none" viewBox="0 0 31 27" :d="iconData">
    <mask
      id="path-1-outside-1_10332_34931"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="29"
      height="26"
      fill="black"
    >
      <rect fill="white" x="1" y="1" width="29" height="26" />
      <path
        d="M5 23V9.5H26V23C26 23.8438 25.2969 24.5 24.5 24.5H6.5C5.65625 24.5 5 23.8438 5 23ZM12.5 13.0625V13.4375C12.5 13.7656 12.7344 14 13.0625 14H17.9375C18.2188 14 18.5 13.7656 18.5 13.4375V13.0625C18.5 12.7812 18.2188 12.5 17.9375 12.5H13.0625C12.7344 12.5 12.5 12.7812 12.5 13.0625ZM26 3.5C26.7969 3.5 27.5 4.20312 27.5 5V7.25C27.5 7.67188 27.125 8 26.75 8H4.25C3.82812 8 3.5 7.67188 3.5 7.25V5C3.5 4.20312 4.15625 3.5 5 3.5H26Z"
      />
    </mask>
  </CustomIcon>
</template>
