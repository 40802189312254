import { defineStore } from 'pinia'
import { watch } from 'vue'
import type { SearchState, SortCondition, SelectOption } from '@/types'
import { ApiService } from '@/services/api-service'
import { ResultType } from '@/types/filters.model'
import { serializeStateToQuery, deserializeQueryToState } from '@/util/search-utils'
import { useFilterStore } from './filters'
import type { preSelectedFilters } from '@/types/filters.model'

const apiService = new ApiService()
const maxSearchResultsPerPage = 30

export const useSearchStore = defineStore('search', {
  // State
  state: (): SearchState => ({
    resultType: ResultType.ALL_RESULTS,
    filters: {
      subjects: [],
      grades: [],
      assessmentTypes: [],
      fieldTypes: [],
      tags: [],
      questions: [],
      authors: []
    },
    searchCriteria: {
      assessmentGuid: '',
      assessmentId: 0,
      folderId: '',
      isDeleted: false,
      resultLimit: maxSearchResultsPerPage,
      searchQuery: '',
      selectedGrades: [],
      selectedAssessmentTypes: [],
      selectedTags: [],
      selectedFieldTypes: [],
      selectedSubjects: [],
      selectedBeginDate: '',
      selectedEndDate: '',
      sortConditions: [],
      selectedStandards: [],
      selectedQuestionRanges: [],
      selectedAuthors: [],
      filterFavorites: false,
      isDraft: false
    },
    searchResults: {
      hitCount: 0,
      returnedCount: 0,
      searchPerformedAt: new Date(),
      assessments: []
    },
    lastFullScreenSearchAssessmentId: null,
    sortConditionSelected: '',
    resetSearchResultScroll: false,
    newData: true,
    hasErrorRetrievingSearchResults: false,
    flags: {}
  }),

  // Getters
  getters: {
    allGrades: (state) => state.filters.grades,
    allAssessmentTypes: (state) => state.filters.assessmentTypes,
    allTags: (state) => state.filters.tags,
    allSubjects: (state) => state.filters.subjects,
    allQuestions: (state) => state.filters.questions,
    currentSearchResults: (state) => state.searchResults,
    getFilters: (state) => state.filters,
    getSortConditionSelected: (state) => state.sortConditionSelected,
    getFavoriteStatusEnabled: (state) => state.resultType === ResultType.FAVORITES,
    getDraftStatusEnabled: (state) => state.resultType === ResultType.DRAFTS,
    getArchiveStatusEnabled: (state) => state.resultType === ResultType.ARCHIVE,
    getQueryString(state): string {
      const query = serializeStateToQuery(state)
      const route = this.router.currentRoute.value
      const resolvedRoute = this.router.resolve({ ...route, query })
      return resolvedRoute.href
    }
  },

  // Actions
  actions: {
    async init() {
      await this.setupSearchCriteriaWatcher()
    },
    initializeFromQuery(query: Record<string, string | string[] | undefined>) {
      const filterStore = useFilterStore()
      const queryState = deserializeQueryToState(query)
      this.resultType = queryState.resultType ?? ResultType.ALL_RESULTS
      if (queryState.searchCriteria) {
        this.searchCriteria = queryState.searchCriteria
      }

      const preSelectedFilters: preSelectedFilters = {
        grade_level_ids: this.searchCriteria.selectedGrades,
        subject_ids: this.searchCriteria.selectedSubjects,
        question_range_ids: this.searchCriteria.selectedQuestionRanges,
        assessment_type_ids: this.searchCriteria.selectedAssessmentTypes,
        author_ids: this.searchCriteria.selectedAuthors
      }

      // This is necessary for the filters on the page to update with the values from the query
      filterStore.updatePreselectedFilters(preSelectedFilters)
    },
    setupSearchCriteriaWatcher() {
      watch(
        () => this.searchCriteria,
        async () => {
          await this.fetchSearchResults()
        },
        {
          deep: true
        }
      )
    },
    async fetchGrades() {
      try {
        const response = await apiService.fetchGrades()
        this.filters.grades = response.data
      } catch (error) {
        console.error('Error fetching assessment types:', error)
      }
    },
    async fetchAssessmentTypes() {
      try {
        const response = await apiService.fetchAssessmentTypes()
        this.filters.assessmentTypes = response.data
      } catch (error) {
        console.error('Error fetching assessment types:', error)
      }
    },
    async fetchTags() {
      try {
        const response = await apiService.fetchTags()
        this.filters.tags = response.data
      } catch (error) {
        console.error('Error fetching tags:', error)
      }
    },
    async fetchSubjects() {
      try {
        const response = await apiService.fetchSubjects()
        this.filters.subjects = response.data
      } catch (error) {
        console.error('Error fetching subjects:', error)
      }
    },
    async fetchSearchResults() {
      try {
        const response = await apiService.fetchSearchResults(this.searchCriteria)
        this.newData = this.searchResults.returnedCount !== response.data.returnedCount
        this.searchResults = response.data
      } catch (error) {
        this.hasErrorRetrievingSearchResults = true
        console.error('Error fetching search results:', error)
      }
    },
    async fetchFieldTypes() {
      try {
        const response = await apiService.fetchFieldTypes()
        this.filters.fieldTypes = response.data
      } catch (error) {
        console.error('Error fetching subjects:', error)
      }
    },
    async fetchQuestions() {
      try {
        const response = await apiService.fetchQuestions()
        this.filters.questions = response
      } catch (error) {
        console.error('Error fetching questions:', error)
      }
    },
    async fetchAuthors() {
      try {
        const response = await apiService.fetchAuthors()
        this.filters.authors = response
      } catch (error) {
        console.error('Error fetching owned by:', error)
      }
    },

    // Updates

    updateResultLimit(newLimit: number) {
      this.searchCriteria.resultLimit = newLimit
    },
    updateSearchQuery(newQuery: string, folderId: string) {
      this.searchCriteria = {
        ...this.searchCriteria,
        searchQuery: newQuery,
        folderId: folderId
      }
    },
    updateSelectedAssessmentTypes(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value)
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: 30,
        selectedAssessmentTypes: values
      }
    },
    updateSortConditionSelected(newSortCondition: string) {
      this.sortConditionSelected = newSortCondition
    },
    updateSelectedTags(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value)
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: 30,
        selectedTags: values
      }
    },
    updatePresetFilters(filterPreset: any) {
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: 30,
        filterFavorites: this.getFavoriteStatusEnabled,
        selectedGrades: filterPreset.grades,
        selectedSubjects: filterPreset.subjects,
        selectedQuestionRanges: filterPreset.questions,
        selectedAssessmentTypes: filterPreset.assessmentTypes,
        selectedAuthors: filterPreset.authors
      }
    },
    updateSelectedGrades(options: SelectOption[]) {
      const values = options.map((option: SelectOption) => {
        return option.value
      })
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        selectedGrades: values
      }
    },
    updateSelectedSubjects(options: SelectOption[]) {
      const values = options.map((option: SelectOption) => {
        return option.value
      })
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        selectedSubjects: values
      }
    },
    updateSelectedStandards(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value.toString())
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: 30,
        selectedStandards: values
      }
    },
    updateSelectedFieldTypes(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value.toString())
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: 30,
        selectedFieldTypes: values
      }
    },
    updateSelectedSortConditions(selectedSortCondtion: SortCondition) {
      const newSortConditions = [selectedSortCondtion]
      this.searchCriteria.sortConditions =
        selectedSortCondtion.field === '' ? [] : newSortConditions
    },
    updateSelectedQuestions(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value.toString())
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        selectedQuestionRanges: values
      }
    },
    updateSelectedAuthors(selectedOptions: SelectOption[]) {
      const values = selectedOptions.map((option) => option.value.toString())
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        selectedAuthors: values
      }
    },
    updateResultType(newResultType: ResultType) {
      this.resultType = newResultType
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        folderId: '',
        isDraft: newResultType === ResultType.DRAFTS,
        filterFavorites: newResultType === ResultType.FAVORITES,
        isDeleted: newResultType === ResultType.ARCHIVE
      }
    },
    updateLastFullScreenSearchAssessmentId(assessmentId: string | null) {
      this.lastFullScreenSearchAssessmentId = assessmentId
    },
    resetStore(activeFolderId: string | null = null) {
      this.resetSearchResultScroll = true
      this.lastFullScreenSearchAssessmentId = null
      this.resultType = this.resultType ? this.resultType : ResultType.ALL_RESULTS
      this.searchCriteria = {
        ...this.searchCriteria,
        resultLimit: maxSearchResultsPerPage,
        folderId: activeFolderId !== null ? activeFolderId : ''
      }
    },
    updateFilterId(filterId: string) {
      this.searchCriteria = {
        ...this.searchCriteria,
        folderId: filterId,
        isDraft: false,
        filterFavorites: false,
        isDeleted: false
      }
    }
  }
})
