<script setup lang="ts">
import { ref } from 'vue'
import ShareIcon from './icons/ShareIcon.vue'
import { useSearchStore } from '@/stores/search'
import { useAssessmentStore } from '@/stores/assessment'
import { useDisplay } from 'vuetify'

const assessmentStore = useAssessmentStore()
const searchStore = useSearchStore()
const { smAndUp } = useDisplay()
const menu = ref(false)
const handleFocus = () => {
  if (menu.value) {
    menu.value = false
  }
}

const generateShareLink = async () => {
  const query = searchStore.getQueryString
  let fullUrl = `${window.location.origin}${query}`
  if (assessmentStore.isAssessmentFullscreen()) {
    fullUrl = window.location.href
  }
  try {
    await navigator.clipboard.writeText(fullUrl)
  } catch (error) {
    console.error('Failed to copy URL to clipboard:', error)
  }
}
</script>

<template>
  <v-menu v-model="menu" location="bottom" offset="10">
    <template v-slot:activator="{ props }">
      <v-btn
        :class="{ 'button-share': smAndUp }"
        v-bind="props"
        density="comfortable"
        variant="text"
        data-test="share-search-button"
        :size="smAndUp ? 'default' : 'small'"
        @focus="handleFocus"
      >
        <template v-slot:append>
          <ShareIcon />
        </template>
      </v-btn>
    </template>
    <v-list v-if="menu" class="share-search-menu" role="list">
      <v-list-item
        key="copy-url-button"
        role="button"
        class="share-link-list-item"
        data-test="copy-url-button"
        aria-label="Copy URL to clipboard"
        @click="generateShareLink"
      >
        <v-list-item-title>Copy URL to clipboard</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped>
.button-share {
  padding-right: 22px;
}
.share-search-menu {
  display: flex;
  flex-direction: column;
  & .share-link-list-item {
    font-family: 'Cerebri Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 17.78px;
  }
}
:deep(.v-list-item__prepend) {
  width: 30px;
  margin-left: 5px;
}

:deep(.v-list) {
  padding: 0;
}

:deep(.v-list-item-title) {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 17.78px;
  margin-right: 5px;
}

:deep(.v-btn__append) {
  margin-inline: 0;
}
</style>
