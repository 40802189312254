<script setup lang="ts">
import type { IconData } from '@/types/icon.model'
import CustomIcon from './CustomIcon.vue'
import InstanceService from '@/services/instance.service'
const iconData: IconData[] = [
  {
    path: 'M52.425 1.98126H57.6824C59.1464 1.98126 60.3707 2.23423 61.3562 2.74067C62.3412 3.24663 63.0734 3.93204 63.5519 4.7969C64.0304 5.66177 64.2696 6.64224 64.2696 7.7388C64.2696 8.84939 63.9878 9.88984 63.425 10.8601C64.241 12.4206 64.6498 14.3752 64.6498 16.7231H62.5596C62.5596 15.0079 62.3766 13.581 62.0104 12.4419C61.1237 13.0886 60.1101 13.4122 58.9701 13.4122C58.0127 13.4122 57.2349 13.1558 56.6368 12.6426C56.0383 12.1294 55.7395 11.4585 55.7395 10.6285C55.7395 9.7989 56.0276 9.13477 56.6054 8.63559C57.1826 8.13689 57.9706 7.88681 58.9701 7.88681C60.1242 7.88681 61.1518 8.28055 62.0531 9.06802C62.1514 8.61817 62.2008 8.18961 62.2008 7.78136C62.2008 6.64272 61.8206 5.69708 61.0608 4.94492C60.3004 4.19276 59.1745 3.81643 57.6824 3.81643H54.5152V16.7231H52.425V1.98126ZM58.0626 11.3347C58.3299 11.5107 58.7028 11.5983 59.1817 11.5983C59.5198 11.5983 59.8607 11.5243 60.206 11.3767C60.5508 11.2292 60.8569 11.0217 61.1247 10.7547C60.5474 10.0519 59.8292 9.70022 58.9711 9.70022C58.5909 9.70022 58.2776 9.78826 58.0316 9.96384C57.7851 10.1399 57.6621 10.3614 57.6621 10.628C57.6616 10.923 57.7952 11.1591 58.0626 11.3347Z',
    fill: 'black'
  },
  {
    path: 'M68.3666 16.3328C67.5782 15.8762 66.9695 15.2295 66.5404 14.3926C66.1109 13.5563 65.8963 12.5826 65.8963 11.4716C65.8963 10.361 66.1109 9.38727 66.5404 8.55046C66.9695 7.71413 67.5855 7.06742 68.3879 6.61031C69.1904 6.1537 70.1333 5.9249 71.2171 5.9249C72.2588 5.9249 73.1489 6.14983 73.8879 6.59967C74.627 7.05 75.1829 7.64738 75.5558 8.39228C75.9287 9.13767 76.1152 9.95272 76.1152 10.8389C76.1152 11.2607 76.073 11.6824 75.9883 12.1042H67.986C68.0984 13.1026 68.4257 13.8794 68.9676 14.4347C69.5096 14.9905 70.2306 15.2677 71.1319 15.2677C71.8075 15.2677 72.3881 15.1095 72.8739 14.7932C73.3596 14.4768 73.7005 14.0444 73.8981 13.4963H76.0309C75.721 14.5929 75.1369 15.4544 74.2783 16.0798C73.4196 16.7057 72.3707 17.0182 71.1324 17.0182C70.0766 17.0182 69.1546 16.7894 68.3666 16.3328ZM74.0884 10.5012C74.1165 9.69974 73.87 9.02836 73.3494 8.48709C72.8283 7.94583 72.0966 7.67495 71.1537 7.67495C70.2805 7.67495 69.5769 7.91729 69.0422 8.40244C68.5071 8.8876 68.1691 9.58704 68.0286 10.5008L74.0884 10.5012Z',
    fill: 'black'
  },
  {
    path: 'M78.0794 6.19917H80.0432V8.43485C80.3667 7.63335 80.8418 7.01518 81.4684 6.57887C82.0946 6.14306 82.8515 5.9249 83.7383 5.9249C84.7654 5.9249 85.5291 6.19917 86.0294 6.7472C86.5287 7.29573 86.8352 7.91438 86.9481 8.60318C87.0604 9.29246 87.1171 10.0794 87.1171 10.9651V16.7439H85.1533V11.0285C85.1533 10.3677 85.118 9.81583 85.0478 9.37276C84.9771 8.92968 84.7945 8.5432 84.4986 8.21283C84.2032 7.88246 83.7383 7.71703 83.1048 7.71703C82.1895 7.71703 81.4505 8.07546 80.8878 8.79279C80.3245 9.50964 80.0432 10.4519 80.0432 11.6186V16.7439H78.0794V6.19917Z',
    fill: 'black'
  },
  {
    path: 'M96.533 15.0989C96.0686 15.7456 95.5121 16.2273 94.8651 16.5437C94.2176 16.86 93.4084 17.0182 92.4369 17.0182C91.3526 17.0182 90.4765 16.7304 89.8082 16.1533C89.1394 15.5768 88.8053 14.8178 88.8053 13.8756C88.8053 12.8351 89.2203 12.0128 90.0509 11.4082C90.8814 10.804 91.9512 10.5012 93.2602 10.5012C94.4002 10.5012 95.3785 10.7474 96.195 11.2394V11.0285C96.195 9.98803 95.9799 9.16912 95.5509 8.57174C95.1213 7.97436 94.4704 7.67543 93.5978 7.67543C92.9357 7.67543 92.3764 7.86214 91.9192 8.23411C91.4616 8.60705 91.2398 9.14445 91.2543 9.84727H89.2484C89.2198 9.0598 89.3927 8.36761 89.7656 7.76976C90.1385 7.17238 90.6664 6.71528 91.3492 6.39893C92.0321 6.08259 92.7885 5.92442 93.6191 5.92442C95.1252 5.92442 96.2618 6.40232 97.0289 7.35861C97.796 8.31489 98.1796 9.53818 98.1796 11.028C98.1796 11.7313 98.0808 12.4128 97.8841 13.0736C98.3907 14.0579 98.6445 15.2812 98.6445 16.743H96.6807C96.6807 16.0972 96.6313 15.5492 96.533 15.0989ZM91.3391 14.9726C91.6771 15.2256 92.1415 15.3523 92.7328 15.3523C93.4225 15.3523 94.0452 15.1627 94.6017 14.783C95.1576 14.4033 95.5761 13.848 95.8579 13.1171C95.5625 12.7795 95.1891 12.5231 94.7387 12.3475C94.2879 12.172 93.7953 12.0839 93.2607 12.0839C92.5706 12.0839 91.9938 12.2493 91.5294 12.5797C91.065 12.9101 90.8325 13.3493 90.8325 13.8978C90.832 14.3607 91.001 14.7191 91.3391 14.9726Z',
    fill: 'black'
  },

  {
    path: 'M100.471 4.13229C100.21 3.86529 100.08 3.54169 100.08 3.16198C100.08 2.76873 100.21 2.44174 100.471 2.18151C100.731 1.92176 101.058 1.79116 101.452 1.79116C101.846 1.79116 102.173 1.92128 102.434 2.18151C102.695 2.44174 102.825 2.76873 102.825 3.16198C102.825 3.54169 102.695 3.86529 102.434 4.13229C102.173 4.3993 101.846 4.5328 101.452 4.5328C101.058 4.53329 100.731 4.39978 100.471 4.13229ZM100.46 6.19917H102.424V16.7439H100.46V6.19917Z',
    fill: 'black'
  },
  {
    path: 'M107.048 14.7404C107.526 15.1762 108.223 15.3944 109.138 15.3944C109.87 15.3944 110.454 15.2609 110.891 14.9939C111.327 14.7269 111.545 14.3685 111.545 13.9181C111.545 13.5529 111.418 13.2642 111.165 13.0533C110.912 12.8424 110.598 12.6881 110.226 12.5894C109.852 12.4912 109.335 12.3858 108.673 12.2731C107.815 12.1328 107.114 11.978 106.573 11.8092C106.031 11.6404 105.57 11.3559 105.19 10.955C104.809 10.5544 104.62 9.98851 104.62 9.25715C104.62 8.24475 105.021 7.43648 105.823 6.83185C106.625 6.2277 107.66 5.9249 108.927 5.9249C109.842 5.9249 110.651 6.0971 111.355 6.4415C112.059 6.7859 112.586 7.25703 112.939 7.8544C113.29 8.45226 113.431 9.11639 113.361 9.84727H111.461C111.503 9.1725 111.288 8.62059 110.816 8.19155C110.345 7.76298 109.701 7.54822 108.885 7.54822C108.223 7.54822 107.685 7.68511 107.27 7.95937C106.854 8.23363 106.647 8.60995 106.647 9.08785C106.647 9.59381 106.879 9.94933 107.344 10.153C107.808 10.3571 108.519 10.5361 109.476 10.6909C110.349 10.8316 111.053 10.9859 111.588 11.1547C112.123 11.3235 112.584 11.6152 112.971 12.0297C113.358 12.4448 113.552 13.0175 113.552 13.7484C113.552 14.7607 113.147 15.5589 112.338 16.1422C111.528 16.726 110.455 17.0172 109.118 17.0172C108.189 17.0172 107.362 16.8557 106.637 16.5321C105.912 16.209 105.356 15.7412 104.969 15.1298C104.581 14.5184 104.416 13.7977 104.472 12.9681H106.373C106.344 13.7145 106.569 14.3046 107.048 14.7404Z',
    fill: 'black'
  },
  {
    path: 'M117.584 14.7404C118.062 15.1762 118.759 15.3944 119.674 15.3944C120.406 15.3944 120.99 15.2609 121.427 14.9939C121.863 14.7269 122.081 14.3685 122.081 13.9181C122.081 13.5529 121.954 13.2642 121.701 13.0533C121.448 12.8424 121.135 12.6881 120.762 12.5894C120.388 12.4912 119.871 12.3858 119.21 12.2731C118.351 12.1328 117.65 11.978 117.109 11.8092C116.567 11.6404 116.106 11.3559 115.726 10.955C115.345 10.5544 115.156 9.98851 115.156 9.25715C115.156 8.24475 115.557 7.43648 116.359 6.83185C117.161 6.2277 118.196 5.9249 119.463 5.9249C120.378 5.9249 121.187 6.0971 121.891 6.4415C122.595 6.7859 123.123 7.25703 123.475 7.8544C123.826 8.45226 123.967 9.11639 123.897 9.84727H121.997C122.039 9.1725 121.824 8.62059 121.352 8.19155C120.881 7.76298 120.237 7.54822 119.421 7.54822C118.759 7.54822 118.221 7.68511 117.806 7.95937C117.39 8.23363 117.183 8.60995 117.183 9.08785C117.183 9.59381 117.415 9.94933 117.88 10.153C118.344 10.3571 119.055 10.5361 120.012 10.6909C120.885 10.8316 121.589 10.9859 122.124 11.1547C122.659 11.3235 123.12 11.6152 123.507 12.0297C123.894 12.4448 124.088 13.0175 124.088 13.7484C124.088 14.7607 123.683 15.5589 122.874 16.1422C122.064 16.726 120.991 17.0172 119.654 17.0172C118.725 17.0172 117.898 16.8557 117.173 16.5321C116.448 16.209 115.892 15.7412 115.505 15.1298C115.117 14.5184 114.952 13.7977 115.008 12.9681H116.909C116.88 13.7145 117.105 14.3046 117.584 14.7404Z',
    fill: 'black'
  },

  {
    path: 'M133.209 15.0989C132.744 15.7456 132.188 16.2273 131.541 16.5437C130.893 16.86 130.084 17.0182 129.113 17.0182C128.028 17.0182 127.152 16.7304 126.484 16.1533C125.815 15.5768 125.481 14.8178 125.481 13.8756C125.481 12.8351 125.896 12.0128 126.727 11.4082C127.557 10.804 128.627 10.5012 129.936 10.5012C131.076 10.5012 132.054 10.7474 132.871 11.2394V11.0285C132.871 9.98803 132.656 9.16912 132.227 8.57174C131.797 7.97436 131.146 7.67543 130.273 7.67543C129.611 7.67543 129.052 7.86214 128.595 8.23411C128.137 8.60705 127.915 9.14445 127.93 9.84727H125.924C125.896 9.0598 126.068 8.36761 126.441 7.76976C126.814 7.17238 127.342 6.71528 128.025 6.39893C128.708 6.08259 129.464 5.92442 130.295 5.92442C131.801 5.92442 132.937 6.40232 133.705 7.35861C134.472 8.31489 134.855 9.53818 134.855 11.028C134.855 11.7313 134.756 12.4128 134.56 13.0736C135.066 14.0579 135.32 15.2812 135.32 16.743H133.356C133.357 16.0972 133.307 15.5492 133.209 15.0989ZM128.015 14.9726C128.353 15.2256 128.817 15.3523 129.409 15.3523C130.098 15.3523 130.721 15.1627 131.277 14.783C131.833 14.4033 132.252 13.848 132.534 13.1171C132.238 12.7795 131.865 12.5231 131.414 12.3475C130.964 12.172 130.471 12.0839 129.936 12.0839C129.246 12.0839 128.669 12.2493 128.205 12.5797C127.741 12.9101 127.508 13.3493 127.508 13.8978C127.508 14.3607 127.677 14.7191 128.015 14.9726Z',
    fill: 'black'
  },
  {
    path: 'M137.03 6.19917H138.994V8.43485C139.317 7.63335 139.792 7.01518 140.419 6.57887C141.045 6.14306 141.802 5.9249 142.689 5.9249C143.716 5.9249 144.48 6.19917 144.98 6.7472C145.479 7.29573 145.786 7.91438 145.899 8.60318C146.011 9.29246 146.068 10.0794 146.068 10.9651V16.7439H144.104V11.0285C144.104 10.3677 144.069 9.81583 143.999 9.37276C143.928 8.92968 143.745 8.5432 143.449 8.21283C143.154 7.88246 142.689 7.71703 142.056 7.71703C141.14 7.71703 140.401 8.07546 139.839 8.79279C139.275 9.50964 138.994 10.4519 138.994 11.6186V16.7439H137.03V6.19917Z',
    fill: 'black'
  },
  {
    path: 'M148.495 8.5611C148.931 7.71752 149.551 7.06742 150.353 6.61031C151.156 6.1537 152.092 5.9249 153.162 5.9249C154.33 5.9249 155.29 6.14983 156.044 6.59967C156.797 7.05 157.339 7.64738 157.669 8.39228C158 9.13767 158.137 9.95997 158.081 10.8597H156.012C156.026 10.7755 156.033 10.642 156.033 10.4592C156.033 9.57349 155.776 8.88808 155.262 8.40293C154.749 7.91777 154.034 7.67543 153.119 7.67543C152.148 7.67543 151.37 8.02322 150.786 8.71927C150.202 9.41532 149.91 10.3324 149.91 11.4716C149.91 12.6107 150.202 13.5278 150.786 14.2238C151.37 14.9199 152.134 15.2677 153.077 15.2677C153.795 15.2677 154.408 15.064 154.914 14.6563C155.421 14.2485 155.78 13.686 155.991 12.9691H158.124C157.884 14.1077 157.329 15.0674 156.456 15.8476C155.583 16.6278 154.45 17.0182 153.057 17.0182C152.029 17.0182 151.121 16.786 150.333 16.3221C149.544 15.8583 148.932 15.2082 148.496 14.3714C148.06 13.535 147.841 12.5681 147.841 11.4716C147.841 10.375 148.059 9.40468 148.495 8.5611Z',
    fill: 'black'
  },

  {
    path: 'M161.882 16.3328C161.094 15.8762 160.485 15.2295 160.056 14.3926C159.626 13.5563 159.412 12.5826 159.412 11.4716C159.412 10.361 159.626 9.38727 160.056 8.55046C160.485 7.71413 161.101 7.06742 161.903 6.61031C162.706 6.1537 163.649 5.9249 164.733 5.9249C165.774 5.9249 166.664 6.14983 167.403 6.59967C168.142 7.05 168.698 7.64738 169.071 8.39228C169.444 9.13767 169.631 9.95272 169.631 10.8389C169.631 11.2607 169.588 11.6824 169.504 12.1042H161.502C161.614 13.1026 161.942 13.8794 162.484 14.4347C163.025 14.9905 163.747 15.2677 164.648 15.2677C165.323 15.2677 165.904 15.1095 166.39 14.7932C166.876 14.4768 167.216 14.0444 167.414 13.4963H169.547C169.237 14.5929 168.653 15.4544 167.794 16.0798C166.936 16.7057 165.887 17.0182 164.648 17.0182C163.592 17.0182 162.67 16.7894 161.882 16.3328ZM167.604 10.5012C167.632 9.69974 167.385 9.02836 166.865 8.48709C166.344 7.94583 165.612 7.67495 164.669 7.67495C163.796 7.67495 163.092 7.91729 162.558 8.40244C162.023 8.8876 161.684 9.58704 161.544 10.5008L167.604 10.5012Z',
    fill: 'black'
  },
  {
    path: 'M52.425 23.5061H57.9783C59.3575 23.5061 60.5789 23.8118 61.6419 24.4237C62.7044 25.0351 63.5316 25.8966 64.1229 27.0072C64.7142 28.1178 65.0096 29.4112 65.0096 30.8875C65.0096 32.3357 64.721 33.6185 64.1437 34.7363C63.5664 35.8542 62.7499 36.7224 61.6942 37.3411C60.6385 37.9597 59.4137 38.2688 58.0204 38.2688H52.425V23.5061ZM57.9783 36.4341C58.9352 36.4341 59.7837 36.2019 60.5227 35.7381C61.2617 35.2742 61.8351 34.6241 62.2434 33.7873C62.6516 32.951 62.8555 31.9773 62.8555 30.8662C62.8555 29.7696 62.655 28.8032 62.2535 27.9664C61.8526 27.1301 61.2821 26.4833 60.5431 26.0262C59.804 25.5696 58.9488 25.3408 57.9778 25.3408H54.5152V36.4341H57.9783Z',
    fill: '#808285'
  },
  {
    path: 'M66.9942 27.724H68.9579V29.9597C69.2814 29.1582 69.7565 28.5401 70.3832 28.1038C71.0094 27.6679 71.7663 27.4498 72.653 27.4498C73.6802 27.4498 74.4439 27.724 74.9442 28.2721C75.4435 28.8206 75.75 29.4393 75.8629 30.1281C75.9752 30.8173 76.0319 31.6043 76.0319 32.49V38.2683H74.0681V32.5534C74.0681 31.8926 74.0327 31.3407 73.9625 30.8976C73.8918 30.4546 73.7092 30.0681 73.4133 29.7377C73.1179 29.4073 72.653 29.2419 72.0196 29.2419C71.1043 29.2419 70.3653 29.6003 69.8025 30.3177C69.2393 31.0345 68.9579 31.9768 68.9579 33.1435V38.2683H66.9942V27.724Z',
    fill: '#808285'
  },

  {
    path: 'M83.1043 23.5061H85.6381L91.5081 38.2688H89.291L87.8973 34.6202H80.9081L79.5143 38.2688H77.213L83.1043 23.5061ZM87.2004 32.7855L84.392 25.4676L81.605 32.7855H87.2004Z',
    fill: '#808285'
  },
  {
    path: 'M21.2242 0C20.0963 0 18.9815 0.099643 17.9097 0.296027C16.839 0.492412 15.7808 0.790858 14.7638 1.18363C13.7807 1.56334 12.8083 2.0422 11.8732 2.60669C10.9922 3.13828 10.1157 3.76371 9.26771 4.46557C8.76115 4.88494 8.3345 5.36187 7.99502 5.88766C7.55868 5.99359 7.24874 6.09082 7.06713 6.15273C6.11068 6.47971 5.25592 7.05823 4.59729 7.82393C3.17157 9.48159 2.049 11.3327 1.26059 13.327C0.424231 15.4428 0 17.6296 0 19.8266C0 21.8064 0.317205 23.7601 0.942898 25.6344C1.55067 27.4546 2.43643 29.164 3.57691 30.7158C4.71062 32.2588 6.07048 33.6112 7.61825 34.7354C9.19991 35.8842 10.9443 36.7703 12.803 37.3686C13.3681 37.551 13.9561 37.6434 14.5498 37.6434C14.5832 37.6434 14.6161 37.6429 14.6495 37.6424C14.9793 37.9786 15.253 38.2282 15.437 38.3883C16.2055 39.0577 17.1421 39.509 18.1451 39.6928C19.2541 39.8965 20.3883 40 21.5162 40C22.7666 40 24.018 39.8738 25.2355 39.6251C26.4244 39.3823 27.5939 39.02 28.7121 38.5484C29.8086 38.086 30.8657 37.5142 31.8547 36.8487C32.8329 36.1903 33.753 35.4343 34.5889 34.6019C35.4253 33.7694 36.1851 32.8528 36.8471 31.8786C37.5169 30.8928 38.0922 29.8388 38.5576 28.7456C39.0322 27.6302 39.3969 26.463 39.6414 25.277C39.8918 24.0614 40.0192 22.8125 40.0192 21.5645C40.0192 20.2721 39.8836 18.9791 39.6153 17.723C39.4855 17.1154 39.2593 16.5374 38.9426 16.0048C38.8922 15.9197 38.8395 15.836 38.7847 15.7543C38.8303 15.5647 38.8642 15.4046 38.8893 15.2788C39.0874 14.2785 39.0119 13.2424 38.6704 12.2818C38.0273 10.4708 37.1091 8.77925 35.942 7.25509C34.8015 5.76528 33.4431 4.46121 31.9055 3.37868C30.3679 2.29663 28.6807 1.45692 26.8908 0.883729C25.0577 0.297478 23.1521 0 21.2242 0Z',
    fill: '#F1EFE3'
  },
  {
    path: 'M34.9308 18.9816C34.8737 18.7146 34.6519 18.524 34.3782 18.507C34.2761 18.5008 34.1782 18.5191 34.0906 18.5583C33.9913 17.8129 33.83 17.0874 33.6126 16.386C33.9792 15.4612 34.1385 14.7574 34.1899 14.4971C34.2107 14.3912 34.2029 14.2814 34.1666 14.1793C33.7007 12.867 33.0353 11.6418 32.1903 10.538C31.3631 9.45789 30.3781 8.51224 29.2618 7.72671C28.1455 6.94117 26.9203 6.3317 25.621 5.91523C24.2921 5.48957 22.9075 5.27335 21.507 5.27335C18.1843 5.27335 15.391 6.41973 12.7894 8.57271C12.5788 8.74732 12.3662 9.01578 12.4882 9.26005C12.557 9.39791 12.57 9.66008 12.7042 9.7128C12.3332 9.99383 12.0068 10.3629 11.6678 10.6797C10.2358 10.8016 9.32389 11.0401 8.99361 11.1533C8.89143 11.1881 8.80038 11.25 8.73016 11.3313C6.65114 13.7484 5.35955 16.8431 5.35955 20.0283C5.35955 21.4615 5.5891 22.8764 6.04191 24.2322C6.48212 25.5512 7.12476 26.791 7.95192 27.9166C8.7752 29.0373 9.76265 30.0192 10.8862 30.8352C12.0325 31.6677 13.296 32.3096 14.6428 32.7435C14.7052 32.7638 14.7692 32.7734 14.8316 32.7734C15.0292 32.7734 15.2166 32.6762 15.3314 32.5035C15.3319 32.5031 15.3319 32.5026 15.3324 32.5021C16.0816 32.8368 16.8671 33.1048 17.6812 33.2988C18.3107 34.092 18.8526 34.5907 19.0551 34.7668C19.1369 34.8384 19.2372 34.8868 19.3442 34.9061C20.1515 35.0546 20.9767 35.1296 21.7976 35.1296C29.2003 35.1296 35.2228 29.1345 35.2228 21.7658C35.2233 20.8283 35.125 19.8914 34.9308 18.9816Z',
    fill: '#41C395',
    clickEvent: true
  },
  {
    path: 'M21.8988 35.2316C21.0779 35.2316 20.2522 35.1562 19.4454 35.0072C19.3384 34.9874 19.2381 34.939 19.1563 34.8674C18.8517 34.6014 17.7799 33.6049 16.7969 31.971C16.238 31.0423 15.7978 30.0574 15.4878 29.0441C15.1091 27.8063 14.9232 26.5153 14.9343 25.2068C14.9488 23.5047 15.3939 21.9472 16.2559 20.5783C16.4123 20.3301 16.7126 20.231 16.9857 20.3369C17.2593 20.4433 17.4138 20.719 17.3615 21.0073C17.3126 21.2758 17.2879 21.55 17.2879 21.8224C17.2879 23.4529 18.1654 24.9776 19.5781 25.8013L19.6013 25.8158C19.6749 25.8637 21.4266 26.9859 23.955 26.9859C25.4098 26.9859 26.82 26.6057 28.146 25.856C30.8861 24.3072 32.4043 22.114 33.1951 20.5468C33.4571 20.0278 33.6843 19.4928 33.8707 18.9559C33.9603 18.6971 34.2049 18.5346 34.479 18.5515C34.7526 18.5685 34.9749 18.76 35.0315 19.0275C35.2257 19.9403 35.324 20.8806 35.324 21.8219C35.324 29.2163 29.3015 35.2316 21.8988 35.2316ZM19.7045 34.1065C20.4275 34.2347 21.1651 34.2995 21.8988 34.2995C28.7867 34.2995 34.3903 28.7026 34.3903 21.8228C34.3903 21.3406 34.3627 20.8588 34.3075 20.3809C34.2194 20.5778 34.1259 20.7737 34.0286 20.9667C33.1796 22.649 31.5495 25.0037 28.6056 26.6676C27.1377 27.4977 25.5725 27.9185 23.955 27.9185C21.2033 27.9185 19.2468 26.6967 19.0977 26.6014C17.5717 25.7075 16.5673 24.1321 16.3842 22.3965C16.0496 23.2715 15.8762 24.2148 15.8675 25.2151C15.8481 27.4401 16.4302 29.552 17.5969 31.4907C18.4478 32.9045 19.3582 33.7941 19.7045 34.1065Z',
    fill: 'black',
    clickEvent: true
  },
  {
    path: 'M23.664 24.1891C23.5632 24.1891 23.4611 24.1872 23.3598 24.1838C23.0669 24.1736 22.83 23.9642 22.7845 23.6745C22.739 23.3852 22.8993 23.1139 23.1748 23.0142C24.0562 22.695 24.8117 22.1218 25.3604 21.3556C25.9207 20.5734 26.2176 19.6515 26.2186 18.6889L26.219 18.6681C26.2249 18.5443 26.2607 17.4187 25.7861 15.9628C25.3328 14.5721 24.3042 12.6281 21.9816 11.2616C20.0256 10.1109 17.8768 9.52754 15.5958 9.52754C14.7672 9.52754 13.942 9.60542 13.1429 9.75827C12.8732 9.81002 12.6102 9.67942 12.4882 9.43419C12.3661 9.18895 12.4209 8.90114 12.6248 8.71782C15.0951 6.49616 18.2855 5.27287 21.6077 5.27287C23.0083 5.27287 24.3928 5.48957 25.7217 5.91668C27.021 6.3346 28.2463 6.94601 29.3625 7.73445C30.4788 8.5224 31.4638 9.47191 32.291 10.5554C33.1365 11.6631 33.8015 12.8927 34.2673 14.2093C34.3037 14.3114 34.3114 14.4217 34.2906 14.5281C34.2126 14.9242 33.8862 16.3463 32.9622 18.0137C32.4363 18.9627 31.8028 19.8372 31.0783 20.6131C30.1936 21.5612 29.1659 22.3694 28.0245 23.0147C26.6457 23.7939 25.1788 24.1891 23.664 24.1891ZM27.1523 18.7005C27.1484 19.8546 26.7915 20.9604 26.1198 21.8983C25.7682 22.3888 25.3464 22.8135 24.8679 23.1617C25.7958 23.0137 26.699 22.693 27.5649 22.2035C29.5064 21.106 31.0473 19.5446 32.1457 17.5629C32.9453 16.12 33.26 14.8899 33.3574 14.4357C32.9254 13.2424 32.3171 12.1279 31.5491 11.1213C30.7795 10.1128 29.8628 9.23006 28.8245 8.49628C27.7857 7.7625 26.6457 7.19366 25.4364 6.80476C24.2001 6.40716 22.9119 6.20594 21.6087 6.20594C18.9098 6.20594 16.3048 7.07322 14.1575 8.66896C14.6336 8.62011 15.1149 8.59544 15.5968 8.59544C18.0468 8.59544 20.3544 9.22232 22.4562 10.4582C25.0306 11.9727 26.1711 14.1305 26.6748 15.674C27.1886 17.2523 27.1595 18.5143 27.1523 18.7005Z',
    fill: 'black',
    clickEvent: true
  },
  {
    path: 'M5.82059 23.5294C5.92665 23.9197 6.05837 24.3018 6.18768 24.6902C6.62789 26.0137 7.22501 26.864 8.05217 27.9935C8.87544 29.1176 9.8629 30.1034 10.9864 30.9223C12.1327 31.7577 13.3962 32.402 14.743 32.8368C14.8055 32.8571 14.8694 32.8668 14.9319 32.8668C15.1295 32.8668 15.3169 32.7691 15.4317 32.5959C15.5832 32.3676 15.5644 32.075 15.3847 31.8679C15.0128 31.4389 14.6617 30.9741 14.3425 30.4875C13.3793 29.0199 12.2368 26.6101 12.264 23.4655C12.2872 20.7727 13.4592 18.9114 14.4379 17.8241C15.498 16.6462 16.5281 16.1166 16.5717 16.0948L16.5949 16.0822C17.2903 15.6837 18.0821 15.4732 18.8856 15.4732C19.9747 15.4732 21.0324 15.8612 21.8639 16.5659C22.0872 16.7551 22.4029 16.7594 22.6315 16.5761C22.8606 16.3923 22.925 16.0827 22.7879 15.8234C22.0319 14.3912 20.9036 13.2269 19.4338 12.3625C17.424 11.1804 15.2166 10.581 12.8737 10.581C10.8605 10.581 9.35973 11.042 8.9476 11.1833C8.84542 11.2181 8.75437 11.28 8.68415 11.3618C7.11798 13.1887 5.9373 15.6164 5.5213 17.9605L6.6153 17.7409C7.01483 15.6493 7.94611 13.6806 9.33503 12.0365C9.80575 11.8832 11.1245 11.5127 12.8737 11.5127C15.0481 11.5127 17.0957 12.0684 18.9601 13.165C19.8241 13.6734 20.5559 14.2954 21.1472 15.0229C20.4406 14.7071 19.6701 14.5397 18.8856 14.5397C17.9233 14.5397 16.9741 14.7912 16.1397 15.2672C16.0143 15.332 14.8772 15.941 13.7439 17.1996C12.6572 18.4069 11.3564 20.4723 11.3308 23.4563C11.3017 26.835 12.5284 29.4219 13.5623 30.9973C13.6819 31.1796 13.8059 31.3596 13.9338 31.5356C13.0887 31.1714 12.2858 30.7133 11.5371 30.1677C10.491 29.4054 9.57233 28.4883 8.80571 27.4416C8.0357 26.3905 7.48168 25.5609 7.07198 24.3294C6.97706 24.044 6.92185 23.8965 6.84679 23.6754L5.82059 23.5294Z',
    fill: 'black',
    clickEvent: true
  }
]
const handleClickIcon = () => {
  InstanceService.navigateToOldAMExperience()
}
</script>

<template>
  <CustomIcon :size="170" viewBox="0 0 170 40" :d="iconData" @click="handleClickIcon"> </CustomIcon>
</template>
