import { defineStore } from 'pinia'
import type { MultiSelectAssessments, AssessmentV1 } from '@/types/assessment.model'

export const useMultiSelectStore = defineStore('multiselect-assessments', {
  state: (): MultiSelectAssessments => ({
    selected_assessments: []
  }),
  getters: {
    currentSelectedAssessments: (state) => state.selected_assessments
  },
  actions: {
    toggleSelectedItem(assessment: AssessmentV1) {
      if (
        this.selected_assessments.some(
          (storeAssessment) => storeAssessment.assessment_id === assessment.assessment_id
        )
      ) {
        this.selected_assessments = this.selected_assessments.filter(
          (selectedAssessment) => selectedAssessment.assessment_id !== assessment.assessment_id
        )
      } else {
        this.selected_assessments = [...this.selected_assessments, assessment]
      }
    },

    assessmentSelected(assessment: AssessmentV1) {
      return (
        this.selected_assessments.filter(
          (selectedAssessment) => selectedAssessment.assessment_id === assessment.assessment_id
        ).length > 0
      )
    },

    toggleAll(assessments: AssessmentV1[]) {
      if (this.selected_assessments.length > 0) {
        this.selected_assessments = []
      } else {
        this.selected_assessments = [...assessments]
      }
    }
  }
})
