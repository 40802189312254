<script setup lang="ts">
import type { Folder } from '@/types/folders.model'
import { computed, ref, type PropType, watch } from 'vue'
import { useFoldersStore } from '@/stores/folders'
import CustomInput from './CustomInput.vue'
import CustomFolderIcon from './icons/CustomFolderIcon.vue'
import CustomTrashIcon from './icons/CustomTrashIcon.vue'
import { useModalStore } from '@/stores/modal'
import { useSpinnerStore } from '@/stores/spinner'
import { useSearchStore } from '@/stores/search'
import { useDisplay } from 'vuetify'
const folderInfo = defineProps({
  folder: {
    type: Object as PropType<Folder>,
    required: true
  },
  classes: {
    type: String,
    required: false
  },
  isDrawerOpen: {
    type: Boolean,
    required: false
  }
})
const display = useDisplay()
const isDrawerOpen = computed(() => folderInfo.isDrawerOpen)
const emit = defineEmits(['closeSideDrawer'])
const listItemFolderRef = ref<any>(null)
const foldersStore = useFoldersStore()
const searchStore = useSearchStore()
const spinnerStore = useSpinnerStore()
const total = computed(() =>
  spinnerStore.requestsPending === 0 ? searchStore.searchResults.hitCount : -1
)
const closeInput = ref(false)
const folderNameDuplicated = computed(() => foldersStore.folderNameDuplicated)
const modalStore = useModalStore()

const onEnterPress = (newName: string) => {
  foldersStore.validateFolderNameDuplicated(newName)

  if (folderInfo.folder && folderNameDuplicated.value.length === 0 && newName.trim().length > 0) {
    foldersStore.changeState('created', folderInfo.folder.folderId)
    newName.trim() !== folderInfo.folder.folderName.trim() &&
      foldersStore.updateFolderName(folderInfo.folder.folderId, newName)
  }
  closeInput.value = false
}

const handleDoubleClick = () => {
  folderInfo.folder?.folderId && foldersStore.changeState('renamed', folderInfo.folder?.folderId)
  closeInput.value = true
}
const handleCloseInput = () => {
  folderInfo.folder.state === 'renamed' &&
    foldersStore.changeState('created', folderInfo.folder.folderId)
  closeInput.value = false
}
const handleDeleteFolder = () => {
  if (folderInfo.folder.assessmentIds.length === 0) {
    foldersStore.deleteFolder(folderInfo.folder.folderId)
  } else {
    modalStore.openModal('delete-folder', {
      modalOpened: true
    })
  }
}

const handleClick = () => {
  foldersStore.setActiveFolder(folderInfo.folder.folderId)
  emit('closeSideDrawer', folderInfo.folder)
}

const renameFolder = () => {
  folderInfo.folder?.folderId && foldersStore.changeState('renamed', folderInfo.folder?.folderId)
  closeInput.value = true
}
const getAriaLabelResults = (folderId: string) => {
  const ariaLabel =
    foldersStore.activeFolderId === folderId && total.value >= 0
      ? `Folder ${folderInfo.folder.folderName} (${total.value} ${total.value > 1 ? 'Results' : 'Result'})`
      : `Folder ${folderInfo.folder.folderName}`
  return ariaLabel
}

watch(
  () => listItemFolderRef.value?.isSelected,
  (newValue) => {
    if (newValue) {
      foldersStore.setActiveFolder(folderInfo.folder.folderId)
    }
  }
)
</script>

<template>
  <CustomInput
    v-if="folder && folder.state === 'renamed' && closeInput"
    :folderNameProp="folder?.folderName"
    @closeInput="handleCloseInput"
    @enterPressed="onEnterPress"
  />
  <template v-if="display.width.value <= 599">
    <v-menu v-if="isDrawerOpen" origin="top center" location="center">
      <template v-slot:activator="{ props }">
        <v-list-item
          v-if="folder && folder.state === 'created'"
          ref="listItemFolderRef"
          :data-test="`${folder.folderName}-folder`"
          :value="folder.folderId"
          :class="{
            'main-v-list': foldersStore.activeFolderId === folder.folderId,
            'v-list-item--active': foldersStore.activeFolderId === folder.folderId
          }"
          v-bind="props"
        >
          <template v-slot:default>
            <v-list-item-title class="pl-6">{{
              foldersStore.activeFolderId === folder.folderId
                ? `${folderInfo.folder.folderName} (${total})`
                : folder.folderName
            }}</v-list-item-title>
          </template>
          <template v-slot:prepend>
            <div><CustomFolderIcon /></div>
          </template>
        </v-list-item>
      </template>
      <v-list role="menu" class="action-folder-menu">
        <v-list-item key="open-folder-button" role="menuitem" @click="handleClick">
          <v-list-item-title>Open Folder</v-list-item-title>
        </v-list-item>
        <v-list-item key="rename-folder-button" role="menuitem" @click="renameFolder()">
          <v-list-item-title>Rename</v-list-item-title>
        </v-list-item>
        <v-list-item key="delete-folder-button" role="menuitem" @click="handleDeleteFolder()">
          <v-list-item-title class="delete-option-text" data-test="delete-folder-button">
            Delete
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </template>
  <template v-else>
    <v-list-item
      v-if="folder && folder.state === 'created'"
      ref="listItemFolderRef"
      key="folder.folderId"
      role="listitem"
      :aria-label="getAriaLabelResults(folder.folderId)"
      :value="folder.folderId"
      active-class="v-list-item--active__results"
      tabindex="0"
      :data-test="`${folder.folderName}-folder`"
      @dblclick="handleDoubleClick"
    >
      <template v-slot:default="{ isActive }">
        <v-list-item-title :aria-hidden="true">{{
          isActive && total > 0 ? `${folderInfo.folder.folderName} (${total})` : folder.folderName
        }}</v-list-item-title>
      </template>
      <template v-slot:prepend>
        <CustomFolderIcon class="ml-2" />
      </template>
      <template v-slot:append="{ isActive }">
        <v-btn
          v-show="isActive"
          min-width="20"
          variant="text"
          density="compact"
          class="px-2"
          aria-label="Delete folder"
          data-test="delete-folder-icon"
          @click="handleDeleteFolder"
        >
          <CustomTrashIcon />
        </v-btn>
      </template>
    </v-list-item>
  </template>
</template>

<style lang="scss" scoped>
.v-list-item--active__results:hover,
.v-list-item--active__results.v-list-item--active {
  background-color: #ffffff;
}

.v-list-item-title {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.v-list-item--active__results:hover::before,
.v-list-item--active__results.v-list-item--active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #008272;
}

.main-v-list {
  cursor: pointer;
}

.main-v-list :deep(.v-list-item-title) {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.main-v-list.v-list-item--active :deep(.v-list-item-title) {
  font-weight: 700;
}

.main-v-list.v-list-item--active {
  background-color: #e5f2f1 !important;
}

.main-v-list:hover::before,
.main-v-list.v-list-item--active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  font-weight: 700;
  background-color: #008272;
}
.action-folder-menu {
  width: 150px;
}

.delete-option-text {
  color: #c31d10;
}

.v-list-item--active__results:hover,
.v-list-item--active__results.v-list-item--active {
  background-color: #ffffff;
}
.v-list-item--active__results:hover::before,
.v-list-item--active__results.v-list-item--active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #008272;
}
</style>
