<script setup lang="ts">
import type { PropType } from 'vue'
import { type IconData } from '@/types/icon.model'
defineProps({
  size: {
    type: Number,
    default: 24
  },
  color: {
    type: String,
    default: '#050f2d'
  },
  d: {
    type: Array as PropType<IconData[]>,
    default: () => []
  },
  viewBox: {
    type: String,
    default: '0 0 512 512'
  }
})
const emit = defineEmits(['click'])
function handleClick() {
  emit('click')
}
</script>
<template>
  <v-icon :size="size" :color="color">
    <template v-slot:default>
      <svg xmlns="http://www.w3.org/2000/svg" :fill="color" :viewBox="viewBox">
        <slot></slot>
        <template v-for="(pathData, index) in d" :key="index">
          <template v-if="pathData.clickEvent">
            <path
              data-test="click-svg-path"
              :d="pathData.path"
              :fill="pathData.fill"
              class="pointer"
              @click="handleClick"
            />
          </template>
          <template v-else>
            <path :d="pathData.path" :fill="pathData.fill" :mask="pathData.mask" />
          </template>
        </template>
      </svg>
    </template>
  </v-icon>
</template>
<style scoped>
.pointer {
  cursor: pointer;
}
</style>
