<script setup lang="ts">
import type { IconData } from '@/types/icon.model'
import CustomIcon from './CustomIcon.vue'
import { useDisplay } from 'vuetify'
const { smAndUp } = useDisplay()
const iconData: IconData = {
  path: 'M12.7969 1.32812L18 6.57812C18.4688 7.04688 18.4688 7.75 18 8.17188C17.5781 8.64062 16.875 8.64062 16.4531 8.17188L13.125 4.84375V16.375C13.125 17.0312 12.6094 17.5 12 17.5C11.3438 17.5 10.875 17.0312 10.875 16.375L10.8281 4.84375L7.5 8.17188C7.07812 8.64062 6.375 8.64062 5.95312 8.17188C5.48438 7.75 5.48438 7.04688 5.95312 6.57812L11.2031 1.32812C11.625 0.90625 12.3281 0.90625 12.7969 1.32812ZM3.375 2.5H3.75C4.35938 2.5 4.875 3.01562 4.875 3.625C4.875 4.28125 4.35938 4.75 3.75 4.75H3.375C2.71875 4.75 2.25 5.26562 2.25 5.875V21.625C2.25 22.2812 2.71875 22.75 3.375 22.75H20.625C21.2344 22.75 21.75 22.2812 21.75 21.625V5.875C21.75 5.26562 21.2344 4.75 20.625 4.75H20.25C19.5938 4.75 19.125 4.28125 19.125 3.625C19.125 3.01562 19.5938 2.5 20.25 2.5H20.625C22.4531 2.5 24 4.04688 24 5.875V21.625C24 23.5 22.4531 25 20.625 25H3.375C1.5 25 0 23.5 0 21.625V5.875C0 4.04688 1.5 2.5 3.375 2.5Z',
  fill: ''
}
</script>
<template>
  <CustomIcon
    class="share-icon"
    :size="smAndUp ? 24 : 16"
    color="#FFFFFF"
    viewBox="0 0 24 25"
    :d="[iconData]"
  />
</template>
<style scoped lang="scss">
.share-icon {
  :hover {
    cursor: pointer;
  }
}
</style>
