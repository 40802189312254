export interface Grade {
  id: number
  name: string
  sort_order: number
}

export interface Subject {
  id: string
  name: string
}

export interface FieldType {
  id: number
  name: string
  count: number
}

export interface FieldTypeV1 {
  id: number
  name: string
}

export interface Tag {
  id: string
  name: string
}

export interface TagV1 {
  id: number
  name: string
}
export interface AssessmentType {
  id: string
  name: string
}

export interface SelectOption {
  value: string
  label: string
  dataTest?: string
}

export interface User {
  id: number
  name: string
}

export interface UserV1 {
  id: number
  name: string
  account_id: string
  first_name: string
  last_name: string
}

export interface Question {
  id: string
  name: string
}

export interface Author {
  id: string
  name: string
}

export interface preSelectedFilters {
  grade_level_ids: string[]
  subject_ids: string[]
  question_range_ids: string[]
  assessment_type_ids: string[]
  author_ids: string[]
}

export enum ResultType {
  ALL_RESULTS = 'ALL_RESULTS',
  FAVORITES = 'FAVORITES',
  DRAFTS = 'DRAFTS',
  ARCHIVE = 'ARCHIVE'
}
