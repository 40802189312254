<script setup lang="ts">
import { computed, ref } from 'vue'
import { useSearchStore } from '@/stores/search'
import { useFilterStore } from '@/stores/filters'
import type { SelectOption } from '@/types'
import Filter from './Filter.vue'
import { useFeatureFlagsStore } from '@/stores/feature-flag'
import { FEATURE_FLAGS } from '@/util/feature-flags.constants'
import { useFoldersStore } from '@/stores/folders'

const searchStore = useSearchStore()
const filterStore = useFilterStore()
const foldersStore = useFoldersStore()
const featureFlagStore = useFeatureFlagsStore()
const activeFolderId = computed(() => foldersStore.getActiveFolderId)

const filters = computed(() => searchStore.getFilters)
const isFilterByQuestionCountEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.FILTER_BY_QUESTION_COUNT]
)

const isAuthorFilterEnabled = computed(
  () => featureFlagStore.flags[FEATURE_FLAGS.ASSESSMENT_OWNER_NAME]
)

const newFiltersSelectionArray = computed(() =>
  Object.keys(filters.value).map((key, index) => {
    return getArrayOfFiltersSelection(key, index)
  })
)

const getArrayOfFiltersSelection = (key: string, index: number) => {
  if (
    (!isFilterByQuestionCountEnabled.value && key === 'questions') ||
    (!isAuthorFilterEnabled.value && key === 'authors')
  ) {
    return {
      [key]: [],
      preSelected: []
    }
  }
  const val = Object.values(filters.value)[index]
  return {
    [key]: val.map((filter: any) => ({
      label: filter.name,
      value: filter.id.toString(),
      dataTest: `${key}-${filter.id}-test`
    }))
  }
}

const resetAll = ref(false)

const clearAllFilters = () => {
  resetAll.value = true
  searchStore.updateSelectedSubjects([])
  searchStore.updateSelectedGrades([])
  searchStore.updateSelectedQuestions([])
  searchStore.updateSelectedAuthors([])
  searchStore.updateSelectedAssessmentTypes([])
  filterStore.clearFilters()
  searchStore.resetStore(activeFolderId.value)
}

const updateReset = () => {
  resetAll.value = false
}

const updateMethods = {
  selectedSubjects: {
    updateSearch: searchStore.updateSelectedSubjects
  },
  selectedGrades: {
    updateSearch: searchStore.updateSelectedGrades
  },
  selectedQuestions: {
    updateSearch: searchStore.updateSelectedQuestions
  },
  selectedAssessmentTypes: {
    updateSearch: searchStore.updateSelectedAssessmentTypes
  },
  selectedAuthors: {
    updateSearch: searchStore.updateSelectedAuthors
  }
}

const handleSelectedUpdate = (
  newOptions: SelectOption[],
  propertyName: keyof typeof updateMethods
) => {
  const updateMethod = updateMethods[propertyName]
  if (updateMethod && !resetAll?.value) {
    searchStore.updateLastFullScreenSearchAssessmentId(null)
    updateMethod['updateSearch'](Array.from(newOptions.values()).map((item: SelectOption) => item))
  } else {
    console.error(`Update method for ${propertyName} not found`)
  }
}
</script>
<template>
  <div class="inline max-width-776">
    <Filter
      v-for="filter in newFiltersSelectionArray"
      :id="`${Object.keys(filter)[0].charAt(0).toUpperCase()}${Object.keys(filter)[0].slice(1)}`"
      class="filter"
      :label="`${Object.keys(filter)[0].charAt(0).toUpperCase()}${Object.keys(filter)[0]
        .slice(1)
        .slice(0, -1)}`"
      :items="filter[Object.keys(filter)[0] as keyof typeof filter]"
      :reset="resetAll"
      @clickOption="
        (newOptions) =>
          handleSelectedUpdate(
            newOptions,
            `selected${Object.keys(filter)[0].charAt(0).toUpperCase()}${Object.keys(
              filter
            )[0].slice(1)}` as keyof typeof updateMethods
          )
      "
      @update:preselectedFilters="
        (newOptions) =>
          handleSelectedUpdate(
            newOptions,
            `selected${Object.keys(filter)[0].charAt(0).toUpperCase()}${Object.keys(
              filter
            )[0].slice(1)}` as keyof typeof updateMethods
          )
      "
      @update:reset="updateReset()"
    />
    <v-btn
      data-test="clear-all-test"
      variant="text"
      class="btn-clear ghost-btn"
      @click="clearAllFilters()"
    >
      Clear All
    </v-btn>
  </div>
</template>

<style scoped>
.max-width-776 {
  max-width: 776px;
  margin: 0 auto;
}
.inline {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  & .filter {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.btn-clear {
  font-size: 12px;
  text-transform: none;
  color: #008272;
  font-family: 'Cerebri Sans';
  font-weight: 500;
  line-height: 25px;
}

:deep(.v-btn--variant-text) .v-btn__overlay {
  background-color: transparent;
  outline: none;
}
.btn-clear:focus-visible {
  border: 2px solid #2b87ff;
}
.btn-clear::after {
  border: none;
}
</style>
