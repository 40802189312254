<script setup lang="ts">
import BackButton from './BackButton.vue'
import { useDisplay } from 'vuetify'
import Header from './Header.vue'

import ShareSearchButton from './ShareSearchButton.vue'
const { xs, smAndUp } = useDisplay()
</script>
<template>
  <Header />
  <v-app-bar
    data-test="toolbar-nav-bar"
    :elevation="2"
    color="#008272"
    :height="xs ? 45 : 58"
    class="main-navbar"
  >
    <BackButton class="absolute-pos mx-0 pr-2 pl-3" :class="{ 'mr-16 pr-5 ml-3': smAndUp }" />
    <v-divider
      color="#FFFFFF"
      thickness="1"
      class="border-opacity-75"
      aria-hidden="true"
      vertical
    ></v-divider>
    <h2 :class="xs ? 'title__mobile' : 'title'">
      {{ $route.meta.viewName || 'Find Assessments' }}
    </h2>
    <!-- Right aligned elements -->
    <template v-slot:append>
      <ShareSearchButton />
    </template>
  </v-app-bar>
</template>

<style scoped lang="scss">
/* Add custom styles here */
.title__mobile {
  padding-left: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.32px;
}

.title {
  padding-left: 36px;
  font-weight: 600;
  line-height: 25.4px;
  font-size: 20px;
}

@mixin clickableIcon() {
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
    transform: scale(1.1);
  }
}

.product-logo {
  @include clickableIcon;
  & {
    width: 32px;
    height: 32px;
    max-width: 35px;
    max-height: 35px;
    display: inline-block;
    padding: 0px 2px;
  }
  @media (max-width: 599px) {
    .main-navbar {
      z-index: 0;
      border-bottom: 1px solid white;
    }
  }
}
</style>
