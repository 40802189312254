<script setup lang="ts">
import { useSearchStore } from '@/stores/search'
import { useSpinnerStore } from '@/stores/spinner'
import { computed, onMounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify'
import { useFoldersStore } from '@/stores/folders'
import { useModalStore } from '@/stores/modal'
import CustomInput from './CustomInput.vue'
import { MAX_FOLDERS } from '@/util/folder.constants'
import { mdiInformationSlabCircle } from '@mdi/js'
import Folder from './Folder.vue'
import { ResultType } from '@/types/filters.model'
import InfoModal from './modals/InfoModal.vue'
import CustomArchiveFolder from './icons/CustomArchiveFolder.vue'
// Re add it once archiving is implemented in folders
// import IconArchive from './icons/IconArchive.vue'

const display = useDisplay()
const foldersStore = useFoldersStore()
const drawer = ref(false)
const allResultsRef = ref<any>(null)
const favoritesRef = ref<any>(null)
const archiveRef = ref<any>(null)
const spinnerStore = useSpinnerStore()
const searchStore = useSearchStore()
const modalStore = useModalStore()
const isFolderBeingCreated = ref(false)
const resultsLabel = ref('All Results')
const foldersToDisplay = computed(() => foldersStore.getFolders)
const isLessThanFifteenFolders = computed(() => foldersStore.getFolders.length < MAX_FOLDERS)
const folderNameDuplicated = computed(() => foldersStore.folderNameDuplicated)
const folderNameDuplicatedMessage = ref('')
const getFolders = () => {
  return foldersToDisplay.value.sort((a, b) => a.folderName.localeCompare(b.folderName))
}
const total = computed(() =>
  !spinnerStore.shouldShowSpinner() ? searchStore.searchResults.hitCount : 0
)

const updateResultsLabel = (label: string, resultType?: ResultType) => {
  resultsLabel.value = label
  if (resultType) {
    searchStore.updateResultType(resultType)
    foldersStore.setActiveFolder(null)
  }
  closeDrawer()
}

const handleFavorites = () => updateResultsLabel('Favorites', ResultType.FAVORITES)
const handleAllResults = () => updateResultsLabel('All Results', ResultType.ALL_RESULTS)
const handleArchive = () => updateResultsLabel('Archive', ResultType.ARCHIVE)
const onCloseSideDrawer = (folder: any) => updateResultsLabel(folder.folderName)

const handleNewFolder = () => {
  isFolderBeingCreated.value = true
}
const handleEnterPressed = (folderNameInput: string) => {
  foldersStore.validateFolderNameDuplicated(folderNameInput)

  if (folderNameDuplicated.value === '') {
    folderNameInput.trim().length > 0 && foldersStore.addFolder(folderNameInput)
  }

  isFolderBeingCreated.value = false
}
const handleCloseInput = () => {
  isFolderBeingCreated.value = false
}

const closeDrawer = () => {
  drawer.value = false
}

watch(
  () => folderNameDuplicated.value,
  (newValue) => {
    if (newValue.length > 0) {
      folderNameDuplicatedMessage.value = `The folder name you chose, ${newValue} , already exists. Please choose a different name.`
      modalStore.openModal('FolderDuplicatedInToolbar', {
        modalOpened: true
      })
      foldersStore.resetFolderNameDuplicated()
    }
  }
)

watch(
  () => display.width.value,
  () => {
    if (display.width.value > 599) {
      closeDrawer()
    }
  }
)

watch(
  () => foldersToDisplay.value,
  (newValue, oldValue) => {
    if (newValue.length < oldValue.length) {
      updateResultsLabel('All Results', ResultType.ALL_RESULTS)
      allResultsRef.value?.activate()
    }
  }
)

onMounted(() => {
  if (searchStore.getFavoriteStatusEnabled) {
    favoritesRef.value.activate()
  } else if (searchStore.getArchiveStatusEnabled) {
    archiveRef.value.activate()
  } else if (allResultsRef.value) {
    allResultsRef.value.activate()
  }
})
</script>
<template>
  <v-app-bar density="compact" color="#00685b" :elevation="0" class="all-results-toolbar">
    <h1 class="all-results-toolbar-title pl-5">{{ resultsLabel }} ({{ total || 0 }})</h1>
    <v-spacer></v-spacer>
    <v-app-bar-nav-icon class="nav-icon" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
  </v-app-bar>
  <v-navigation-drawer
    v-model="drawer"
    class="folder-toolbar"
    :width="240"
    :location="$vuetify.display.mobile ? 'right' : undefined"
    temporary
  >
    <div class="close-container">
      <v-btn
        density="compact"
        size="small"
        icon="$close"
        variant="text"
        color="#050F2D"
        class="close-drawer-btn"
        @click="closeDrawer()"
      />
    </div>
    <v-list>
      <v-list-item
        ref="allResultsRef"
        key="allResults"
        value="allResults"
        class="main-v-list"
        @click="handleAllResults"
      >
        <v-list-item-title
          >All Results {{ resultsLabel === 'All Results' ? `(${total})` : '' }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item
        ref="favoritesRef"
        key="favorites"
        value="favorites"
        class="main-v-list"
        base-color="#008272"
        @click="handleFavorites"
      >
        <v-list-item-title
          >Favorites {{ resultsLabel === 'Favorites' ? `(${total})` : '' }}</v-list-item-title
        >
      </v-list-item>
      <v-divider
        class="border-opacity-100 ml-2 mr-2 mt-3 mb-3"
        color="#80C0B8"
        :thickness="1"
      ></v-divider>
      <div class="folder-container">
        <template v-for="folder in getFolders()" :key="folder.folderId">
          <Folder
            classes="main-v-list"
            :folder="folder"
            :isDrawerOpen="drawer"
            @closeSideDrawer="onCloseSideDrawer"
          />
        </template>
      </div>

      <v-list class="v-list-custom">
        <CustomInput
          v-if="isFolderBeingCreated"
          @enterPressed="handleEnterPressed"
          @closeInput="handleCloseInput"
        />
      </v-list>

      <v-list-item
        v-if="isLessThanFifteenFolders"
        key="newFolder"
        value="newFolder"
        class="action-v-list"
        @click="handleNewFolder"
      >
        <template v-slot:prepend>
          <div>
            <v-icon size="14" color="#008272" tag="span">
              <font-awesome-icon :icon="['fas', 'plus']" />
            </v-icon>
          </div>
        </template>
        <v-list-item-title :style="{ color: '#008272' }" class="pl-6 new-folder-option">
          New Folder
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-else>
        <template v-slot:prepend>
          <v-icon :icon="mdiInformationSlabCircle" color="#008272" class="ml-2"></v-icon>
        </template>
        <v-list-item-subtitle class="alert-subtitle">
          You can only have 15 folders. Delete a folder to add a new one.
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item
        ref="archiveRef"
        key="archive"
        value="Archive"
        class="main-v-list"
        base-color="#008272"
        @click="handleArchive"
      >
        <template v-slot:prepend>
          <CustomArchiveFolder />
        </template>
        <v-list-item-title
          >Archive {{ resultsLabel === 'Archive' ? `(${total})` : '' }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
    <InfoModal
      :uniqueId="'FolderDuplicatedInToolbar'"
      :message="folderNameDuplicatedMessage"
      :title="'Duplicate Folder Name'"
      :labelButton="'OK'"
    />
  </v-navigation-drawer>
</template>

<style scoped>
:deep(.v-list-item__prepend > .v-icon) {
  opacity: 1;
}
:deep(.v-list-item__prepend > .v-icon ~ .v-list-item__spacer) {
  width: 22px;
}
:deep(.v-toolbar__content) {
  height: 41px !important;
}

.nav-icon {
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  text-align: center;
  padding: 5px;
  width: unset !important;
  height: unset !important;
  margin-top: 2px;
}

.v-list-item-title--active {
  font-weight: 700;
}

.new-folder-option {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.v-navigation-drawer__scrim {
  height: calc(100% + 190px);
}
.v-list-custom {
  & .v-list-item,
  & .v-list-item--active {
    background-color: #ffffff;
    &:hover {
      background-color: #ffffff !important;
    }
  }
}
nav.folder-toolbar {
  height: fit-content !important;
  padding-bottom: 10px;
  border-radius: 5px;
  z-index: 1006 !important;
  top: 115px !important;
  right: 1px !important;
  border: 1px solid #e0e1e6;
}
.all-results-toolbar {
  display: flex;
  justify-content: center;
  height: 41px;
  border-bottom: 1px solid transparent;
  margin-top: 1px;
}

.all-results-toolbar-title {
  font-family: Cerebri Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-drawer-btn {
  font-weight: 300;
  line-height: 16px;
  text-align: center;
}

:deep(.v-list) {
  padding: 0;
}

:deep(.v-list-item--rounded) {
  border-radius: 0;
}

:deep(.v-list-item) {
  padding: 0 10px;
  margin: 0;
  border-radius: none;
  color: #050f2d;
}

:deep(.v-list .v-list-item--nav:not(:only-child)) {
  margin-bottom: 0;
}

.main-v-list {
  cursor: pointer;
  color: #050f2d !important;
}

.main-v-list.v-list-item--active {
  background-color: #e5f2f1 !important;
}

.main-v-list :deep(.v-list-item-title) {
  font-family: 'Cerebri Sans';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
}

.main-v-list.v-list-item--active :deep(.v-list-item-title) {
  font-weight: 700;
}

.main-v-list.v-list-item--active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background-color: #008272;
}

.main-v-list.v-list-item--active > .v-list-item__overlay,
.main-v-list.v-list-item--active:hover > .v-list-item__overlay,
.action-v-list.v-list-item--active > .v-list-item__overlay,
.action-v-list.v-list-item--active:hover > .v-list-item__overlay {
  opacity: 0;
}

:deep(.v-list-item--active > .v-list-item__overlay) {
  opacity: 0;
}

.folder-container {
  max-height: 400px;
  overflow-y: auto;
}

.alert-subtitle {
  display: flex;
}

:deep(.v-list-item--density-default) {
  padding-inline: 16px !important;
}
</style>
