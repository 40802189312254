import { defineStore } from 'pinia'
import { featureFlagService } from '../services/feature-flag.service'
import { onUnmounted } from 'vue'
import type { LDFlagSet } from 'launchdarkly-js-client-sdk'

export const useFeatureFlagsStore = defineStore('featureFlags', {
  state: (): LDFlagSet => ({
    flags: {}
  }),
  actions: {
    initialize() {
      return new Promise<void>((resolve, reject) => {
        featureFlagService.initializeLaunchDarkly()

        const cleanup = () => {
          featureFlagService.destroy()
        }

        onUnmounted(cleanup)

        featureFlagService.onReady(() => {
          this.flags = featureFlagService.getFlags()
          resolve()
        })
        featureFlagService.onChange(() => {
          this.flags = featureFlagService.getFlags()
        })

        featureFlagService.onError(() => {
          reject('Failed to initialize the LD client')
        })
      })
    }
  }
})
